import React, { useState } from "react"
import { Link } from "gatsby"
import { graphql, StaticQuery } from "gatsby"
import Helmet from "react-helmet"
import LogoLeft from "../../../images/digitalwert-logo-left.svg"
import LogoRight from "../../../images/digitalwert-logo-right.svg"
import LogoLeftLight from "../../../images/digitalwert-logo-left-light.svg"
import LogoRightLight from "../../../images/digitalwert-logo-right-light.svg"
import styles from "./topHeaderWithMenu.module.css"
import window from "global"

const TopHeaderWithMenu = props => {
  const [menu, setMenu] = useState(false)
  const [submenu, setSubMenu] = useState(false)
  const [itemNumber, setItemNumber] = useState('')
  const [displaySubItems, setDisplaysubItems] = useState(false)

  let intViewportWidth = window.innerWidth;

  return (
    <StaticQuery
      query={graphql`
        query MenuDataQuery {
          allDatoCmsNavigationMain(
            filter: { root: { eq: true } }
            sort: { fields: position, order: ASC }
          ) {
            nodes {
              ...nav_main
              treeChildren {
                ...nav_main
              }
            }
          }
        }
      `}
      render={data => (
        <nav
          className={`${styles.container} 
            ${ props.page === "themenseite" ? styles.themenseitePosition : ""} 
            ${ props.page === "jobs" ? styles.absolute : ""} 
            ${ props.page === "index" || props.page === "impressum" ? styles.indexWitdth : ""}
            ${styles.indexWitdth}`}
        >
          <a href="/">
            {(props.page === "index" || intViewportWidth <= 1024) && (
              <div className={styles.logo}>
                <img src={LogoLeftLight} className={styles.logoLeft} alt="Digitalwert logo" />
                <img src={LogoRightLight} className={styles.logoRight} alt="Digitalwert logo" />
              </div>
            )}
            {(props.page !== "index" && intViewportWidth >= 1025) && (
              <div className={styles.logo}>
                <img src={LogoLeft} className={styles.logoLeft} alt="Digitalwert logo" />
                <img src={LogoRight} className={styles.logoRight} alt="Digitalwert logo" />
              </div>
            )}
          </a>
          <Helmet htmlAttributes={{ lang: "de" }}>
            <body className={`${menu ? styles.body : ""} ${props.bg === "dark" ? styles.bodyDark : ""}`}/>
          </Helmet>
          <div
            className={styles.rightHeaderContainer}
            style={menu ? { justifyContent: "flex-end" } : {}}
          >
            <Link to="/kontakt/" state={{ prevPath: props.url }} className={styles.primaryButton} style={props.page === "themenseite" ? { visibility: "hidden" } : {}}>
                <span>Bilde ein Team mit uns</span>
            </Link>
            <div className={styles.menuButton}>
            <button
              aria-label="Menu"
              className={`${styles.menu} ${styles.themenseiteIcon}
              } ${menu ? styles.closeMenu : ""} `}
              onClick={() => {setMenu(!menu); if (menu === false) {setSubMenu(false); setDisplaysubItems(false); setItemNumber('')}}}
              style={menu ? { position: "static" } : {}}
              ></button>
              </div>
            <div
              className={`${styles.menuArea} ${menu ? styles.fullMenu : ""}`}
              style={
                menu ? { visibility: "visible" } : { visibility: "hidden" }
              }
            >
              <div
                className={`${styles.menuPositionAndSize} ${styles.subitemChanges}`}
                style={submenu ? { overflow: "auto" } : {}}
              >
                <div className={styles.MainItems}>
                  <div
                    className={`${styles.firstPartContainer} 
                    `}
                  >
                    <div className={`${styles.menuFirstPartItems} `} 
                    >
                      {data.allDatoCmsNavigationMain.nodes.map(
                        (menuNodes, index) => {
                          return (
                            <div key={index}
                              className={styles.mainItemAndSubItemsContainer}
                            >
                              <button
                                style={
                                  itemNumber === index + 1
                                    ? {
                                        backgroundColor: "#FFF",
                                        color: "#980A88",
                                      }
                                    : {}
                                }
                              >
                                {menuNodes.title}
                              </button>
                              <div className={styles.ulContainer}>
                              <ul
                                key={index}
                                className={`${styles.mobileSubMenuItemsContainer} ${(displaySubItems) ? styles.displayOn : styles.displayOff}`}
                              >
                                {menuNodes.treeChildren.map(
                                  (mobileSubMenuItems, index) => {
                                    return (
                                        <li key={index}>
                                          <a
                                          href={
                                            '/' + mobileSubMenuItems.link.url
                                          }
                                          key={index}
                                          >
                                          {mobileSubMenuItems.title}
                                          </a>
                                      </li>
                                    )
                                  }
                                )}
                              </ul>
                              </div>
                            </div>
                          )
                        }
                      )}
                    </div>
                  </div>
                  <div
                    className={`${styles.secondPartContainer} ${styles.displayChangeBasedOnSubItems}`}
                    style={{ display: "block" } }
                  >
                    <div className={styles.jobs}>
                      <a href="/jobs/">
                        Jobs
                      </a>
                      <a href="/news/">
                        News
                      </a>
                      <a href="/kontakt/">
                        Kontakt
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </nav>
      )}
    />
  )
}
export default TopHeaderWithMenu
